import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {CRQ_FORM_FOCUS, MPxNS} from "../../common/constants";
import {identifierType} from "../../common/commonUtilities";


function useLoadedMPxN(props) {
    const desiredIdentifier = props.name.toUpperCase()
    if (!MPxNS.includes(desiredIdentifier)) {
        return null
    }
   const loadedIdentifier = document.getElementById("device-id").value
    return identifierType(loadedIdentifier) === desiredIdentifier ? loadedIdentifier : null
  }

//Generalised fragment for all variables that do not have fixed validation as a certain type to make consistent formatting
export default function SmartGeneral(props) {
  return (
    <Form.Group as={Row} className="p-2">
      <Form.Label column sm="3">{props.label}</Form.Label>
      <Col sm="9" name={CRQ_FORM_FOCUS}>
        <Form.Control
          as={props.multiline ? "textarea" : "input"} // Use "textarea" if multiline is true
          name={props.name}
          id={props.name}
          placeholder={props.placeholder}
          required={props.required}
          pattern={props.pattern}
          title={props.title}
          size={props.size}
          maxLength={props.maxLength}
          minLength={props.minLength}
          defaultValue={useLoadedMPxN(props) || props.defaultValue}/>
        <Form.Control.Feedback type="invalid">{props.feedback}</Form.Control.Feedback>
      </Col>
    </Form.Group>
  )
}

